import React from "react";
import * as S from "./styled";

const BasicPage = ({ children, title }) => {
  return (
    <S.BasicWrapper>
      <S.BasicTitle>{title}</S.BasicTitle>
      <S.BasicContent>
        {children}
      </S.BasicContent>
    </S.BasicWrapper>
  );
};

export default BasicPage;
