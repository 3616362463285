import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import BasicPage from "../components/BasicPage";

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Sobre mim" />
      <BasicPage title="Sobre mim">
        <p>
          Programo desde 2002 como hobby, e desde 2004 profissionalmente. Isso é
          muita coisa, e já passei por tantas mudanças que constantemente me
          questiono qual o real valor da experiência numa área com uma defasagem
          de conhecimento tão rápida.
        </p>
        <p>
          Porém, meus interesses vão muito além da tecnologia. Sou apaixonado
          por literatura, por quadrinhos, narrativas, descobrir como certas
          coisas funcionam, ler coisas perturbadoras que martelam na cabeça por
          semanas. Punhetação intelectual em geral.
        </p>
        <p>
          O custo disso é um sem número de coisas iniciadas mas nunca
          terminadas. No entanto, coisas que podem ser revisitadas de tempos em
          tempos. E o ciclo se reinicia.
        </p>
        <p>
          Esse foi basicamente um dos motivos por eu ter, inicialmente, dividido
          os posts desse site (ou blog?) entre tecnologia e miscelânea, onde
          nesta última me darei a liberdade para escrever sobre literalmente
          qualquer coisa que passar pela minha cabeça. No fundo, comecei esse
          site no intuito de ser um exercício sob vários aspectos, e espero que
          ele evolua ao longo do tempo, em forma e conteúdo.
        </p>
        <p>
          (Meu tempo é mais escasso do que gostaria. Por esse motivo o site será
          construído aos poucos, ao mesmo tempo em que irei escrevendo os posts.
          Me preocupei em colocar o mínimo necessário no ar.)
        </p>
      </BasicPage>
    </Layout>
  );
};

export default AboutPage;
